
// - [ ] Add a star next to the ones I am passionate about ...
// - [ ] Link these to jira! What am I currently tinkering with ...
// - [ ] Interlace the tools I built and add a custom indicator!
// Add a custom visualization of these skill sets as a graph!


// {
//     "category": "",
//     "footnote": "",
//     "description": "",
//     "skills" : [
//         {
//             "skill": "",
//             "experience": [
//             ]
//         },
//     ]
// },

export const legend = [
    {label: "python", format: "py", link: "https://www.python.org/"},
    {label: "java", format: "java", link: "https://www.java.com/en/"},
    {label: "javascript", format: "js"},
    {label: "html", format: "html"},
    {label: "css", format: "css"},
    {label: "golang", format: "go"},
    {label: "c/c++", format: "c"},
    {label: "typescript", format: "ts"},
    {label: "scala", format: "scala", link: "https://www.scala-lang.org/"},
    {label: "haskell", format: "haskell"},
    {label: "applescript", format: "osx"},
    {label: "openscad", format: "tag"},
    {label: "assembly", format: "tag"},
    {label: "prolog", format: "tag"},
    {label: "tinkering", format: "tinkering"},
];

export const skillsets = [
    {
        "category": "Languages",
        "skills" : [
            {
                "skill": "Programming Languages",
                "experience": [
                    {label: "python", format: "py", link: "https://www.python.org/"},
                    {label: "java", format: "java", link: "https://www.java.com/en/"},
                    {label: "javascript", format: "js"},
                    {label: "html", format: "html"},
                    {label: "css", format: "css"},
                    {label: "golang", format: "go"},
                    {label: "c/c++", format: "c"},
                    {label: "typescript", format: "ts"},
                    {label: "scala", format: "scala", link: "https://www.scala-lang.org/"},
                    {label: "haskell", format: "haskell"},
                    {label: "applescript", format: "osx"},
                    {label: "openscad", format: "tag"},
                    {label: "assembly", format: "tag"},
                    {label: "prolog", format: "tag"},
                ]
            },
            {
                "skill": "Scripting Languages",
                "experience": [
                    {label: "bash", format: "sh"},
                    {label: "zsh", format: "sh"},
                    {label: "sh", format: "sh"},
                ]
            },
            {
                "skill": "Query Languages",
                "experience": [
                    {label: "sql", format: "tag"},
                    {label: "cypher", format: "tag", link: "https://neo4j.com/developer/cypher/"},
                    {label: "mongo", format: "tag", link: "https://docs.mongodb.com/manual/reference/operator/"},
                    {label: "jsonpath", format: "tag", link: "https://jsonpath.com/"},
                    {label: "SPARQL", format: "tag", link: "https://www.w3.org/TR/sparql11-query/"},
                ]
            },
            {
                "skill": "Templating Languages",
                "experience": [
                    {label: "cookie-cutter", format: "py"},
                    {label: "jinja", format: "py"},
                    {label: "go templates", format: "go", link: "https://golang.org/pkg/text/template/"},
                ]
            }
        ]
    },
    {
        "category": "Libraries",
        "footnote": "#def-library",
        "skills" : [
            {
                "skill": "CLI Development",
                "experience": [
                    {"label": "typer", "link": "https://typer.tiangolo.com/", "format": "py"},
                    {"label": "click", "link": "https://click.palletsprojects.com/en/7.x/", "format": "py"},
                    {"label": "argparse", "format": "py"},
                    {"label": "termcolor", "link": "https://pypi.org/project/termcolor/", "format": "py"},
                    {"label": "optparse", "link": "https://github.com/sellmerfud/optparse", "format": "scala"},
                ]
            },
            {
                "skill": "Database Connectors",
                "experience": [
                    {"label": "kids.cahce", "format": "py" },
                    {"label": "kairos", "format": "py" },
                    {"label": "sqlalchemy", "format": "py" },
                    {"label": "happybase", "format": "py" },
                    {"label": "pika", "format": "py" }
                ]
            },
            {
                "skill": "Debugging",
                "experience": [
                    {"label": "ipdb", "format": "py"},
                ]
            },
            {
                "skill": "Functional Programming",
                "experience": [
                    {"label": "lodash", "link": "https://lodash.com/", "format": "js"},
                    {"label": "pydash", "link": "https://pydash.readthedocs.io/en/latest/", "format": "py"},
                    {"label": "functools", "format": "py"},
                    {"label": "scalaz", "format": "scala"},
                ]
            },
            {
                "skill": "General Utilities",
                "experience": [
                    {"label": "itertools", "format": "py"},
                    {"label": "toolz", "format": "py"},
                    {"label": "more-itertools", "format": "py"},
                ]
            },
            {
                "skill": "Graphs / Networks",
                "experience": [
                    {"label": "networkx", "link": "https://networkx.org/", "format": "py"},
                ]
            },
            {
                "skill": "Parsing",
                "experience": [
                    {"label": "beautifulsoup4", "link": "https://www.crummy.com/software/BeautifulSoup/bs4/doc/", "format": "py"},
                    {"label": "lark", "link": "https://github.com/lark-parser/lark", "format": "py"}
                ]
            },
            {
                "skill": "Performance/Load Testing",
                "experience": [
                    {"label": "locust", "link": "https://locust.io/", "format": "py"},
                    {"label": "Apache JMeter", "link": "https://jmeter.apache.org/", "format": "java"},
                ]
            },
            {
                "skill": "Protecting IP",
                "experience": [
                    {"label": "pyarmour", "link": "https://pypi.org/project/pyarmor/", "format": "py"}
                ]
            },
            {
                "skill": "Schema Definition",
                "experience": [
                    {"label": "pydantic", "format": "py"},
                    {"label": "attrs", "format": "py"},
                    {"label": "dataclasses", "format": "py"},
                ]
            },
            {
                "skill": "Schema Validation",
                "experience": [
                    {"label": "jsonschema", "link": "https://pypi.org/project/jsonschema/", "format": "py"},
                    {"label": "joi", "link": "https://github.com/sideway/joi#readme", "format": "js"},
                ]
            },
            {
                "skill": "Serialization",
                "experience": [
                    {"label": "dill", "format": "py"},
                ]
            },
            {
                "skill": "Synthetic Data Generation",
                "experience": [
                    {"label": "Faker", "link": "https://faker.readthedocs.io/en/master/", "format": "py"},
                    {"label": "mimesis", "link": "https://mimesis.readthedocs.io/foreword.html", "format": "tinkering"},
                ]
            },
            {
                "skill": "Testing",
                "experience": [
                    {"label": "pytest", "format": "py"},
                    {"label": "unittest", "format": "py"},
                    {"label": "mock", "format": "py"},
                    {"label": "mocket", "format": "py"},
                    {"label": "tox", "format": "py"},
                ]
            },
            {
                "skill": "Time Management",
                "experience": [
                    {"label": "arrow", "link": "https://arrow.readthedocs.io/en/latest/", "format": "py"},
                    {"label": "maya", "link": "https://github.com/timofurrer/maya", "format": "py"},
                ]
            },
            {
                "skill": "Type Checking",
                "experience": [
                    {"label": "mypy", "link": "https://pypi.org/project/mypy/", "format": "py"},
                ]
            },
           
        ]
    },
    {
        "category": "Frameworks",
        "footnote": "#def-framework",
        "skills" : [
            {
                "skill": "Event Proccessing Frameworks",
                "experience": [
                    {"label": "celery", "link": "https://docs.celeryproject.org/en/stable/index.html", "format": "py"},
                    {"label": "twisted", "format": "py"},
                    {"label": "akka", "format": "scala"},
                ]
            },
            {
                "skill": "Data Processing Frameworks",
                "experience": [
                    {"label": "Apache Beam", "link": "https://beam.apache.org/", "format": "tag"},
                    {"label": "Spark Streaming", "link": "https://spark.apache.org/streaming/", "format": "scala"}
                ]
            }
        ]
    },
    {
        "category": "Web Development",
        "skills" : [
            {
                "skill": "API Development Frameworks",
                "experience": [
                    {"label": "Flask", "link": "https://flask.palletsprojects.com/en/1.1.x/", "format": "py"},
                    {"label": "fastapi", "link": "https://fastapi.tiangolo.com/", "format": "py"},
                    {"label": "starlette", "link": "https://www.starlette.io/", "format": "py"},
                    {"label": "express", "link": "https://expressjs.com/", "format": "js"},
                    {"label": "django", "link": "https://www.djangoproject.com/", "format": "py"},
                ]
            },
            {
                "skill": "Frontend Development Frameworks",
                "experience": [
                    {"label": "react", "format": "js"},
                    {"label": "angular", "format": "js"},
                ]
            },
            {
                "skill": "REST Clients",
                "experience": [
                    {"label": "requests", "format": "py"},
                    {"label": "httpie", "link": "https://httpie.io/", "format": "sh"},
                    {"label": "curl", "format": "tag"},
                    {"label": "postman", "format": "tag"},
                ]
            },
            {
                "skill": "GraphQL Clients",
                "experience": [
                    {"label": "GraphiQL", "format": "tag"},
                ]
            },
            {
                "skill": "Retries",
                "experience": [
                    {"label": "tenacity", "format": "py"},
                ]
            },
            {
                "skill": "Roles & Permissions",
                "experience": [
                    {"label": "pyjwt", "link": "https://pyjwt.readthedocs.io/en/stable/", "format": "py"},
                    {"label": "pycasbin", "link": "https://github.com/casbin/pycasbin", "format": "tinkering"},
                ]
            },
            {
                "skill": "Crawling/Scraping",
                "experience": [
                    {"label": "Scrapy", "link": "https://scrapy.org/", "format": "py"},
                    {"label": "Selenium", "link": "https://selenium-python.readthedocs.io/", "format": "py"},
                ]
            },
            {
                "skill": "API Integrations",
                "experience": [
                    {"label": "Brandwatch API", "format": "tag"},
                    {"label": "Slack API", "format": "tag"},
                    {"label": "Twitter API", "format": "tag"},
                    {"label": "Jira API", "format": "tag"},
                    {"label": "Trello API",  "format": "tag"},
                    {"label": "GOCD API",  "format": "tag"},
                    {"label": "Jenkins API",  "format": "tag"},
                    {"label": "Github API",  "format": "tag"},
                    {"label": "Google API", "format": "tag"},
                    {"label": "AWS API", "format": "tag"},
                    {"label": "Azure API", "format": "tag"},
                    {"label": "Quandl API", "link": "https://www.quandl.com/tools/api", "format": "link"},
                    {"label": "DBPedia Spotlight API", "link": "https://www.dbpedia-spotlight.org/", "format": "link"},
                ]
            },
            {
                "skill": "Static Site Generators",
                "experience": [
                    {"label": "hugo", "link": "https://gohugo.io/about/what-is-hugo/", "format": "link"},
                    {"label": "Jekyll", "link": "https://github.com/jekyll/jekyll", "format": "link"},
                    {"label": "Sphinx", "link": "https://www.sphinx-doc.org/en/master/", "format": "link"},
                    {"label": "docusaurus", "link": "https://docusaurus.io/en/", "format": "js"},
                ]
            },
        ]
    },
    {
        "category": "AI Development",
        "skills" : [
            {
                "skill": "Data Science",
                "experience": [
                    {"label": "pandas", "link": "https://pypi.org/project/pandas/", "format": "py"},
                    {"label": "numpy", "link": "https://pypi.org/project/numpy/", "format": "py"},
                ]
            },
            {
                "skill": "Data Visualization",
                "experience": [
                    {"label": "matplotlib", "link": "https://matplotlib.org/", "format": "py"},
                    {"label": "d3", "link": "https://d3js.org/", "format": "js"},
                    {"label": "seaborn", "link": "https://seaborn.pydata.org/", "format": "py"},
                    {"label": "bokeh", "link": "https://docs.bokeh.org/en/latest/index.html", "format": "py"},
                ]
            },
            {
                "skill": "Model Development",
                "experience": [
                    {"label": "scikit-learn", "format": "py"},
                    {"label": "pytorch", "format": "py"},
                ]
            },
            {
                "skill": "NLP",
                "experience": [
                    {"label": "spacy", "link": "https://spacy.io/", "format": "py"},
                    {"label": "Stanford NLP", "link": "https://nlp.stanford.edu/", "format": "tinkering"},
                ]
            },
        ]
    },
    {
        "category": "Packaging",
        "description": "How do I package my code & consume other packages?",
        "skills" : [
            {
                "skill": "Package Managers",
                "experience": [
                    {"label": "pip", "link": "https://pypi.org/project/pip/", "format": "py"},
                    {"label": "conda", "link": "https://pypi.org/project/conda/", "format": "py"},
                    {"label": "brew", "link": "https://brew.sh/", "format": "osx"},
                    {"label": "npm", "link": "https://www.npmjs.com/", "format": "js"},
                    {"label": "maven/mvn", "link": "https://maven.apache.org/", "format": "java"},
                    {"label": "sbt", "format": "scala"},
                    {"label": "bower", "format": "js"},
                    {"label": "yum", "format": "sh"},
                    {"label": "apt-get", "format": "sh"},
                    {"label": "apk", "format": "sh"},
                    {"label": "helm", "format": "docker"},
                    {"label": "nix", "link": "https://nixos.org/manual/nix/stable/#chap-introduction", "format": "sh"},
                ]
            },
            {
                "skill": "Package Repositories",
                "experience": [
                    {"label": "pypi", "link": "https://pypi.org/", "format": "py"},
                    {"label": "anaconda/conda-forge", "link": "https://conda-forge.org/#about", "format": "py"},
                    {"label": "Central Repositry", "link": "https://central.sonatype.org/#:~:text=The%20Central%20Repository%20is%20the,Gradle%20and%20many%20other%20tools.", "format": "java"},
                    {"label": "artifactory", "link": "https://jfrog.com/artifactory/", "format": "link"},
                    {"label": "hackage", "link": "https://hackage.haskell.org/", "format": "haskell"},
                    {"label": "scaladex", "link": "https://index.scala-lang.org/", "format": "scala"},
                    {"label": "npm registry", "link": "https://www.npmjs.com/", "format": "js"},
                    {"label": "cdnjs", "link": "https://cdnjs.com/", "format": "js"},
                ]
            },
            {
                "skill": "Container Registries",
                "experience": [
                    {"label": "dockerhub", "link": "https://hub.docker.com/", "format": "link"},
                    {"label": "AWS ecr", "link": "https://aws.amazon.com/ecr/", "format": "aws"},
                ]
            },
            {
                "skill": "Code Repositories",
                "experience": [
                    {"label": "github", "format": "tag"},
                    {"label": "bitbucket", "format": "tag"},
                    {"label": "gitlab", "format": "tag"},
                ]
            },
        ]
    },
    {
        "category": "Systems",
        "footnote": "#def-system",
        "description": "What different sofware systems have I used? ... Includes Servers, Engines, ...",
        "skills" : [
            {
                "skill": "Operating Systems",
                "experience": [
                    {"label": "Linux", "format": "tag"},
                    {"label": "Mac OSX / MacOS", "format": "tag"},
                    {"label": "Microsoft Windows", "format": "tag"},
                ]
            },
            {
                "skill": "Linux Distributions",
                "experience": [
                    {"label": "Alpine", "format": "tag"},
                    {"label": "Ubuntu", "format": "tag"},
                    {"label": "Centos", "format": "tag"},
                    {"label": "Redhat", "format": "tag"},
                    {"label": "Debian", "format": "tag"},
                ]
            },
            {
                "skill": "Infrastructure Automation",
                "experience": [
                    {"label": "Puppet", "link": "https://puppet.com/", "format": "link"},
                    {"label": "Ansible", "link": "https://www.ansible.com/", "format": "link"},
                    {"label": "Fabric", "link": "http://www.fabfile.org/", "format": "link"},
                    {"label": "AWS AMIs", "link": "https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/AMIs.html", "format": "aws"},
                ]
            },
            {
                "skill": "Monitoring Systems",
                "experience": [
                    {"label": "prometheus", "link": "https://prometheus.io/", "format": "link"},
                    {"label": "nagios", "link": "https://www.nagios.com/", "format": "link"},
                    {"label": "ganglia", "link": "http://ganglia.sourceforge.net/", "format": "link"},
                    {"label": "AWS Cloudwatch", "format": "tag"},
                    {"label": "Splunk", "format": "tag"},
                ]
            },
            {
                "skill": "Data Collectors",
                "experience": [
                    {"label": "syslog", "format": "tag"},
                    {"label": "fluentd", "link": "https://www.fluentd.org/", "format": "link"},
                ]
            },
            {
                "skill": "Search Engines",
                "experience": [
                    {"label": "elasticsearch", "link": "https://www.elastic.co/what-is/elasticsearch", "format": "link"},
                ]
            },
            {
                "skill": "Serverless Computation Systems",
                "experience": [
                    {"label": "AWS Lambda", "format": "tag"},
                    {"label": "IBM OpenWhisk", "format": "tag"},
                ]
            },
            {
                "skill": "Distributed Computation Systems",
                "experience": [
                    {"label": "Spark", "link": "https://spark.apache.org/", "format": "scala"},
                ]
            },
            {
                "skill": "Access Management Systems",
                "experience": [
                    {"label": "AWS IAM", "format": "aws"},
                    {"label": "AWS Cognito", "format": "aws"},
                ]
            },
            {
                "skill": "Data Visualization Systems",
                "experience": [
                    {"label": "Grafana", "link": "https://grafana.com/", "format": "link"},
                    {"label": "Kibana", "link": "https://www.elastic.co/kibana", "format": "link"},
                ]
            },
            {
                "skill": "Data Processing Pipelines",
                "experience": [
                    {"label": "Talend", "link": "https://www.talend.com/", "format": "link"},
                    {"label": "Apache Nifi", "link": "https://nifi.apache.org/", "format": "link"},
                    {"label": "AWS Kinesis Firehose", "link": "https://aws.amazon.com/kinesis/data-firehose", "format": "aws"},
                    {"label": "logstash", "link": "https://www.elastic.co/logstash", "format": "link"},
                ]
            },
            {
                "skill": "Secret Management Systems",
                "experience": [
                    {"label": "Vault", "link": "https://www.vaultproject.io/", "format": "link"},
                    {"label": "lastpass", "link": "https://www.lastpass.com/", "format": "link"},
                ]
            },
            {
                "skill": "Service Discovery Systems",
                "experience": [
                    {"label": "Consul", "format": "tag"},
                ]
            },
            {
                "skill": "Proxies/Load Balancers/API Gateways",
                "experience": [
                    {"label": "HA Proxy", "link": "https://www.haproxy.com/", "format": "link"},
                    {"label": "nginx", "link": "https://www.nginx.com/", "format": "link"},
                    {"label": "kong", "link": "https://konghq.com/kong/", "format": "link"},
                    {"label": "Azure API Management", "link": "https://docs.microsoft.com/en-us/azure/api-management/", "format": "link"},
                    {"label": "AWS API Gateway", "link": "https://aws.amazon.com/api-gateway/", "format": "link"},
                    {"label": "envoy", "link": "https://www.envoyproxy.io/docs/envoy/latest/#", "format": "tinkering"},
                ]
            },
        ]
    },
    {
        "category": "Data Stores",
        "description": "*including Databases, Blob Stores, etc*",
        "skills" : [
            {
                "skill": "RDBMSs",
                "experience": [
                    {"label": "Postgresql", "format": "tag"},
                    {"label": "Mssql", "format": "tag"},
                    {"label": "Mysql", "format": "tag"},
                    {"label": "sqlite", "format": "tag"},
                ]
            },
            {
                "skill": "Document Stores",
                "experience": [
                    {"label": "Mongo", "format": "tag"},
                    {"label": "Cassandra", "format": "tag"},
                ]
            },
            {
                "skill": "Blob Stores",
                "experience": [
                    {"label": "AWS S3", "format": "aws"},
                    {"label": "Azure Blob Store", "format": "tag"},
                ]
            },
            {
                "skill": "In Memmory Stores",
                "experience": [
                    {"label": "Redis", "format": "tag"},
                ]
            },
            {
                "skill": "Messaging Systems",
                "experience": [
                    {"label": "Rabbitmq", "format": "tag"},
                    {"label": "Apache Kafka", "format": "tag"},
                    {"label": "Redis streams", "format": "tag"},
                    {"label": "AWS SQS", "format": "tag"},
                ]
            },
            {
                "skill": "Graph Databases",
                "experience": [
                    {"label": "Neo4j", "format": "tag"},
                    {"label": "OrientDB", "format": "tag"},
                ]
            },
            {
                "skill": "Shared Filesystems",
                "experience": [
                    {"label": "SFTP", "format": "tag"},
                    {"label": "NFS", "format": "tag"},
                ]
            },
            {
                "skill": "File Sync Systems",
                "experience": [
                    {"label": "Dropbox", "format": "tag"},
                    {"label": "Google Drive", "format": "tag"},
                    {"label": "Microsoft onedrive", "format": "tag"},
                ]
            },
            {
                "skill": "Knowledge Graphs",
                "experience": [
                    {"label": "dbpedia", "format": "tag"},
                ]
            },
        ]
    },
    {
        "category": "Platforms",
        "description": "What platforms have I used?",
        "skills" : [
            {
                "skill": "AWS",
                "experience": [
                    {"label": "Route53", "format": "aws"},
                    {"label": "EC2", "format": "aws"},
                    {"label": "S3/S3 Batch", "format": "aws"},
                    {"label": "IAM", "format": "aws"},
                    {"label": "SQS", "format": "aws"},
                    {"label": "SNS", "format": "aws"},
                    {"label": "CodeBuild", "format": "aws"},
                    {"label": "CodePipeline", "format": "aws"},
                ]
            },
            {
                "skill": "Azure",
                "experience": [
                    {"label": "API Gateway", "format": "tag"},
                    {"label": "RDS", "format": "tag"},
                ]
            },
            {
                "skill": "Data Platforms",
                "experience": [
                    {"label": "Snowflake", "link": "https://www.snowflake.com/", "format": "link"},
                ]
            },
            {
                "skill": "AI Platforms",
                "experience": [
                    {"label": "Cortex", "link": "https://www.cognitivescale.com/cortex/", "format": "link"},
                ]
            },
        ]
    },
    {
        "category": "CI/CD",
        "description": "",
        "skills" : [
            {
                "skill": "Build Pipelines",
                "experience": [
                    {"label": "Jenkins", "link": "https://www.jenkins.io/", "format": "link"},
                    {"label": "gocd", "link": "https://www.gocd.org/", "format": "link"},
                    {"label": "GitHub Actions", "link": "https://github.com/features/actions", "format": "link"},
                    {"label": "AWS CodeBuild", "format": "aws"},
                    {"label": "AWS CodePipeline", "format": "aws"},
                    {"label": "Travis CI", "link": "https://travis-ci.org/", "format": "link"},
                ]
            },
            {
                "skill": "Package Builders",
                "experience": [
                    {"label": "webpack", "format": "js"},
                    {"label": "make", "format": "sh"},
                ]
            },
            {
                "skill": "Package Uploaders",
                "experience": [
                    {"label": "twine", "format": "py"},
                ]
            },
            {
                "skill": "Compilers",
                "experience": [
                    {"label": "javac", "format": "java"},
                    {"label": "scalac", "format": "scala"},
                    {"label": "gcc", "format": "c"},
                ]
            },
            {
                "skill": "Linters",
                "experience": [
                    {"label": "flake8", "format": "py"},
                    {"label": "ESLint", "link": "https://eslint.org/", "format": "js"},
                ]
            },
        ]
    },
    {
        "category": "Command Line Tools",
        "footnote": "#def-command-line-tool",
        "description": "",
        "skills" : [
            {
                "skill": "Shell Preferences",
                "experience": [
                    {"label": "sh", "format": "sh"},
                    {"label": "zsh", "format": "sh"},
                    {"label": "bash", "format": "sh"},
                ]
            },
            {
                "skill": "Text Wrangling",
                "experience": [
                    {"label": "sed", "format": "sh"},
                    {"label": "awk", "format": "sh"},
                    {"label": "cut", "format": "sh"},
                    {"label": "tr", "format": "sh"},
                ]
            },
            {
                "skill": "Iteration",
                "experience": [
                    {"label": "xargs", "format": "sh"},
                ]
            },
            {
                "skill": "Version Control",
                "experience": [
                    {"label": "git", "format": "sh"},
                    {"label": "diff", "format": "sh"},
                ]
            },
            {
                "skill": "Search Tools",
                "experience": [
                    {"label": "ag", "format": "sh"},
                    {"label": "grep", "format": "sh"},
                    {"label": "find", "format": "sh"},
                ]
            },
            {
                "skill": "Built Tools",
                "experience": [
                    {"label": "make", "format": "sh"},
                ]
            },
            {
                "skill": "Parsing",
                "experience": [
                    {"label": "jq", "format": "sh"},
                ]
            },
            {
                "skill": "Networking Tools",
                "experience": [
                    {"label": "ping", "format": "sh"},
                    {"label": "traceroute", "format": "sh"},
                    {"label": "ifconfig", "format": "sh"},
                ]
            },
        ]
    },
    {
        "category": "Virtualization",
        "description": "",
        "skills" : [
            {
                "skill": "Containerization",
                "experience": [
                    {"label": "docker", "format": "docker"},
                    {"label": "docker-compose", "format": "docker"},
                    {"label": "kubectl", "format": "docker"},
                    {"label": "k9s", "format": "docker"},
                ]
            },
            {
                "skill": "Cloud CLIs",
                "experience": [
                    {"label": "az", "format": "tag"},
                    {"label": "aws", "format": "tag"},
                ]
            },
            {
                "skill": "Virtual Machines",
                "experience": [
                    {"label": "vagrant", "link": "https://www.vagrantup.com/", "format": "link"},
                    {"label": "VirtualBox", "link": "https://www.virtualbox.org/", "format": "link"},
                    {"label": "Parallels", "link": "https://www.parallels.com/", "format": "link"},
                ]
            },
            {
                "skill": "Virtualization",
                "experience": [
                    {"label": "VMWare", "link": "https://www.vmware.com/", "format": "link"},
                    {"label": "XenServer", "link": "https://www.citrix.com/products/citrix-hypervisor/", "format": "link"},
                ]
            },
            {
                "skill": "Remote Access",
                "experience": [
                    {"label": "ssh", "format": "sh"},
                    {"label": "putty", "format": "tag"},
                ]
            },
        ]
    },
    {
        "category": "Development Environments",
        "description": "",
        "skills" : [
            {
                "skill": "Interactive Environments",
                "experience": [
                    {"label": "jupyter lab", "format": "py"},
                    {"label": "jupyter notebook", "format": "py"},
                    {"label": "ipython", "format": "py"},
                    {"label": "google collaboratory", "format": "tag"},
                    {"label": "Zepplin", "format": "tag"},
                ]
            },
            {
                "skill": "Virtual Environments",
                "experience": [
                    {"label": "virtualenv", "link": "https://pypi.org/project/virtualenv/", "format": "py"},
                    {"label": "nvm", "format": "js"},
                ]
            },
            {
                "skill": "IDEs / Development Environments",
                "experience": [
                    {"label": "vscode", "format": "tag"},
                    {"label": "pycharm", "format": "tag"},
                    {"label": "atom", "format": "tag"},
                    {"label": "sublime", "format": "tag"},
                    {"label": "vi/vim", "format": "tag"},
                    {"label": "intellij", "format": "tag"},
                    {"label": "netbeans", "format": "tag"},
                ]
            },
            {
                "skill": "VPNs",
                "experience": [
                    {"label": "Cisco AnyConnect", "link": "https://www.cisco.com/c/en/us/products/security/anyconnect-secure-mobility-client/index.html", "format": "link"},
                ]
            },
            {
                "skill": "Remote Meetings",
                "experience": [
                    {"label": "Zoom", "format": "tag"},
                    {"label": "Microsoft Teams", "format": "tag"},
                    {"label": "Skype", "format": "tag"},
                    {"label": "Cisco Webex", "format": "tag"},
                ]
            },
        ]
    },    
    {
        "category": "Applications",
        "description": "",
        "skills" : [
            {
                "skill": "Diagraming",
                "experience": [
                    {"label": "plantuml/puml", "link": "https://plantuml.com/", "format": "link"},
                    {"label": "mermaid", "link": "https://mermaid-js.github.io/mermaid/#/", "format": "link"},
                    {"label": "lucidchart", "link": "https://www.lucidchart.com/", "format": "link"},
                    {"label": "draw.io", "link": "https://www.draw.io/", "format": "link"},
                    {"label": "gliffy", "format": "tag"},
                    {"label": "Google Drawing", "format": "tag"},
                    {"label": "omnigraffle", "format": "tag"},
                ]
            },
            {
                "skill": "Presenting",
                "experience": [
                    {"label": "Google Slides", "format": "tag"},
                    {"label": "Microsoft Powerpoint ", "format": "tag"},
                    {"label": "Keynote #osx", "format": "tag"},
                ]
            },
            {
                "skill": "Task Management",
                "experience": [
                    {"label": "Jira", "format": "tag"},
                    {"label": "Trello", "format": "tag"},
                    {"label": "zendesk", "format": "tag"},
                    {"label": "NotePlan", "format": "tag"},
                ]
            },
            {
                "skill": "Note Taking",
                "experience": [
                    {"label": "iA Writer", "format": "tag"},
                ]
            },
            {
                "skill": "WIKIs",
                "experience": [
                    {"label": "Confluence", "format": "tag"},
                    {"label": "gitbooks", "format": "tag"},
                    {"label": "google docs", "format": "tag"},
                ]
            },
            {
                "skill": "Cataloging",
                "experience": [
                    {"label": "Microsoft Excel", "format": "tag"},
                    {"label": "Google Sheets", "format": "tag"},
                    {"label": "Numbers #osx", "format": "tag"},
                ]
            },
            {
                "skill": "Code Scanning",
                "experience": [
                    {"label": "sonarqube", "link": "https://www.sonarqube.org/", "format": "link"},
                ]
            },
            {
                "skill": "Wireframes",
                "experience": [
                    {"label": "invision", "link": "https://www.invisionapp.com/", "format": "link"},
                ]
            },
        ]
    },
    {
        "category": "Formats",
        "description": "What file formats do I use? ",
        "skills" : [
            {
                "skill": "File Formats",
                "experience": [
                    {"label": "json", "format": "tag"},
                    {"label": "yaml", "format": "tag"},
                    {"label": "xml", "format": "tag"},
                    {"label": "markdown", "format": "tag"},
                    {"label": "md", "format": "tag"},
                    {"label": "csv", "format": "tag"},
                    {"label": "tsv", "format": "tag"},
                ]
            },
            {
                "skill": "Knowledge Representations / Ontology",
                "experience": [
                    {"label": "RDF", "format": "tag"},
                ]
            },
            {
                "skill": "Compression Formats",
                "experience": [
                    {"label": "tar", "format": "tag"},
                    {"label": "tgz", "format": "tag"},
                    {"label": "zip", "format": "tag"},
                ]
            },
            {
                "skill": "Serialization Formats",
                "experience": [
                    {"label": "avro", "format": "tag"},
                    {"label": "parquet", "format": "tag"},
                    {"label": "pickle", "format": "py"},
                ]
            },
            {
                "skill": "Package Formats",
                "experience": [
                    {"label": "containers", "format": "tag"},
                    {"label": "rpms", "format": "tag"},
                    {"label": "jars", "format": "java"},
                    {"label": "jars", "format": "scala"},
                    {"label": "eggs/wheels", "format": "py"},
                    {"label": "nifi jar", "format": "java"},
                    {"label": "brew formulae", "format": "sh"},
                ]
            },
            {
                "skill": "Encodings",
                "experience": [
                    {"label": "utf-8", "format": "tag"},
                    {"label": "ascii", "format": "tag"},
                    {"label": "unicode", "format": "tag"},
                ]
            },
        ]
    },
    {
        "category": "Standards / Conventions",
        "description": "",
        "skills" : [
            {
                "skill": "Standards",
                "experience": [
                    {"label": "ISO 8601", "link": "https://en.wikipedia.org/wiki/ISO_8601", "format": "link"},
                    {"label": "redfish", "link": "https://www.dmtf.org/standards/redfish", "format": "link"},
                ]
            },
            {
                "skill": "Specifications",
                "experience": [
                    {"label": "REST APIs", "link": "https://www.w3.org/2001/sw/wiki/REST", "format": "link"},
                    {"label": "OpenAPI/Swagger", "link": "https://swagger.io/docs/specification/about/", "format": "link"},
                ]
            },
            {
                "skill": "Networking Protocols",
                "experience": [
                    {"label": "TCP/IP", "format": "tag"},
                    {"label": "HTTP/HTTPS", "format": "tag"},
                    {"label": "WMI", "format": "tag"},
                    {"label": "IPMI", "format": "tag"},
                    {"label": "SSDP", "format": "tag"},
                    {"label": "SNMP", "format": "tag"},
                    {"label": "SMTP", "format": "tag"},
                ]
            },
            {
                "skill": "Security Protocols",
                "experience": [
                    {"label": "JWT", "format": "tag"},
                    {"label": "OAUTH", "format": "tag"},
                    {"label": "LDAP", "format": "tag"},
                ]
            },
        ]
    },
    {
        "category": "Patterns / Best Practices",
        "description": "",
        "skills" : [
            {
                "skill": "Architectural Patterns",
                "experience": [
                    {"label": "Event Processing", "format": "tag"},
                    {"label": "Microservices", "format": "tag"},
                    {"label": "Service Oriented Architectures", "format": "tag"},
                    {"label": "Business Process Management", "format": "tag"},
                    {"label": "High Availability (HA)", "format": "tag"},
                    {"label": "Data Mesh", "format": "tag"},
                    {"label": "ACID", "format": "tag"},
                    {"label": "State Management", "format": "tag"},
                    {"label": "Containerization", "format": "tag"},
                    {"label": "Package Management", "format": "tag"},
                ]
            },
            {
                "skill": "Design Patterns",
                "experience": [
                    {"label": "Connectors", "format": "tag"},
                    {"label": "Bridges", "format": "tag"},
                    {"label": "Iterators", "format": "tag"},
                ]
            },
            {
                "skill": "Styling Code",
                "experience": [
                    {"label": "pep8", "format": "py"},
                    {"label": "styleguide", "link": "https://google.github.io/styleguide/", "format": "link"},
                ]
            },
            {
                "skill": "Versioning",
                "experience": [
                    {"label": "Semantic Versioning", "link": "https://semver.org/", "format": "link"},
                ]
            },
        ]
    },
    {
        "category": "Development Process",
        "description": "",
        "skills" : [
            {
                "skill": "Development Methodologies",
                "experience": [
                    {"label": "Agile", "format": "tag"},
                    {"label": "Waterfall", "format": "tag"},
                    {"label": "togaf", "format": "tag"},
                ]
            },
            {
                "skill": "Development Approach",
                "experience": [
                    {"label": "refactoring", "format": "tag"},
                    {"label": "iterating", "format": "tag"},
                    {"label": "mvps", "format": "tag"},
                    {"label": "stubs", "format": "tag"},
                ]
            },
        ]
    },
];

export const definitions = [
    {
        "term": "Library",
        "definition": "Reusable code component I leverage by directly invoking the provided functionality.",
        "usages": [
            "#libraries"
        ]
    },
    {
        "term": "Framework",
        "definition": "Reusable code component I leverage by configuring the provided functionality to invoke my code.",
        "usages": [
            "#frameworks"
        ]
    },
    {
        "term": "System",
        "definition": "Server, engine, service, etc.",
        "usages": [
            "#systems"
        ]
    },
    {
        "term": "Command Line Tool",
        "definition": "A reference to executables / commands / binaries / tools that can be invoked through a terminal like interface.",
        "usages": [
            "#command-line-tools"
        ]
    }
];

export const footnotes = [];