import './css/App.css';
import './css/Links.css';
import './css/Lists.css';
import './css/Footnotes.css';
import ReactMarkdown from 'react-markdown'
import PortfolioNavBar from './NavBar';
import { nameToAnchor, deriveId, isTabletOrMobileQuery /*, deriveNavigationFromSkillsets*/} from './Utils';
import { getIconForFormat } from './Icons';
import { useMediaQuery } from 'react-responsive'
import { ProjectCarousel } from "./Carousel";

// https://blog.logrocket.com/how-to-safely-render-markdown-using-react-markdown/
// https://github.com/remarkjs/react-markdown

function Tag(props) {
  const classes = [props.format, "tag"].join(' ');
  if (props.link) {
    return (
      <li className={classes}>
        <a href={props.link}> 
          {getIconForFormat(props.format)}
          {props.label}
        </a>
      </li>
    )
  }
  return (
    <li className={classes}>
      {getIconForFormat(props.format)}
      {props.label}
    </li>
  );
}

function ConditionalFootnote(props) {
  if (props.footnote) {
    return (
      <a className="footnote" aria-label="footnote" href={props.footnote}></a>
    );  
  }
  return (<></>);
}

function SkillSet(props) {
  const _id = deriveId(props.category);
  return (
    <>
      <h1 id={_id}>{props.category}{ConditionalFootnote(props)}</h1>
      {
        props.skills.map((skill) => (
          <div className="FlexElement">
            <h2 id={nameToAnchor(skill.skill)}> {skill.skill}</h2>
            <ul className="tags">
                {skill.experience.map((tag) => Tag(tag))}
            </ul>
          </div>
        ))
      }
    </>
  );
}

function Intro() {
  const introMarkdown = `
# Intro

👋🏽 Hi, I'm Omar  
🎓 Graduated from UT Dallas w/ a degree in Computer Engineering & a minor in Business Administration. (Dec 2013)  
🌇 Austin Native, with a passion for building valuable tech creatively!  
🔬 I've been building some cutting-edge AI Solutions for Fortune 500 companies.  
🔮 I hope to one day facilitate the production of valuable tech ideas with global impact within a talented think tank.  
☕️ In my free time, you can find me brewing lattes, automating my home, tinkering with code, and making arts-and-crafts programmatically!  
💪🏽 I'm great at bringing tech to life!  
  `;

  return (
    <ReactMarkdown>{introMarkdown}</ReactMarkdown>
  );
}

function Mindset() {
  const mindsetMarkdown = `
# Mindset

🧠 If you peeked into my mind, you would find a growth-oriented mindset, organized thoughts, and design-thinking all over the walls.  
🫀 If you peaked into my emotions, you would find a burning passion for tech, and eagerness to learn.  
👻 If you peeked into my fears, you would notice that failing is absent.    
  `;

  return (
    <ReactMarkdown>{mindsetMarkdown}</ReactMarkdown>
  );
}

function Definition(props) {
  const _id = `def-${props.term.toLowerCase()}`;
  return (
    <>
      <div id={_id}>
          <kbd>{props.term}</kbd> ~ {props.definition}
          {
            props.usages.map(
              (usage) => (
                <a href={usage} aria-label="Back to content">&crarr;</a>
              )
            )
          }
      </div>
    </>
  );
}

function Footer(definitions) {
 return (
  <>
    <h1 id="definitions-and-footnotes">Footnotes / Definitions</h1>
    <footer>
      {definitions.map((definition) => Definition(definition))}
    </footer>
  </>
 );
}

function Portfolio(props) {
  const isTabletOrMobile = useMediaQuery(isTabletOrMobileQuery);
  const definitions = props.definitions;
  const skillsets = props.skillsets;
  const legend = props.legend;
  // const navigationalTags = deriveNavigationFromSkillsets(skillsets);
  return (
    <div className="MainArea">
      {PortfolioNavBar(skillsets)}
      <div className="FlexContainer AreaWithMargin">
        {
          !isTabletOrMobile 
          &&
            <div className="SideArea FlexElement FlexVerticalContainer AreaWithPadding">
              {/* <h1 className="dark">Legend</h1> */}
              {/* https://www.linkedin.com/pulse/how-create-linkedin-badge-your-website-brynne-tillman/ */}
              <div className="LI-profile-badge" data-version="v1" data-size="large" data-locale="en_US" data-type="vertical"
                  data-theme="dark" data-vanity="oeid">
                  <a className="LI-simple-link" href='https://www.linkedin.com/in/oeid?trk=profile-badge'>Omar Eid</a>
              </div>
              <br/>
              <img className="GithubStats" src="https://github-readme-stats.vercel.app/api/top-langs/?username=omars-lab" alt="Top Langs"></img>
              <br/>
              <h1 className="dark">Legend</h1>
              <ul className="tags">
                {legend.map((tag) => Tag(tag))}
              </ul>
            </div>
        }
        <div className="ContentArea FlexElement FlexContainer AreaWithPadding">
          {Intro()}
          {Mindset()}
          {ProjectCarousel()}
          {skillsets.map((skillset) => SkillSet(skillset))}
          {Footer(definitions)}
        </div>
      </div>
    </div>
  );
}

export default Portfolio;