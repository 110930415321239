import COriginalIcon from "react-devicons/c/original";
import PythonOriginalIcon from "react-devicons/python/original";
import JavaOriginalIcon from "react-devicons/java/original";
import Html5OriginalIcon from "react-devicons/html5/original";
import JavascriptOriginalIcon from "react-devicons/javascript/original";
import HaskellOriginalIcon from "react-devicons/haskell/original";
import GoOriginalIcon from "react-devicons/go/original";
import ScalaOriginalIcon from "react-devicons/scala/original";
import Css3OriginalIcon from "react-devicons/css3/original";
import BashOriginalIcon from "react-devicons/bash/original";
import TypescriptOriginalIcon from "react-devicons/typescript/original";
import AppleOriginalIcon from "react-devicons/apple/original";

export function getIconForFormat(format) {
  if (format === "c") {
    return <COriginalIcon/>
  } else if (format === "py") {
    console.log(format)
    return <PythonOriginalIcon/>
  } else if (format === "java") {
    return <JavaOriginalIcon/>
  } else if (format === "html") {
    return <Html5OriginalIcon/>
  } else if (format === "js") {
    return <JavascriptOriginalIcon/>
  } else if (format === "haskell") {
    return <HaskellOriginalIcon/>
  } else if (format === "go") {
    return <GoOriginalIcon/>
  } else if (format === "scala") {
    return <ScalaOriginalIcon/>
  } else if (format === "css") {
    return <Css3OriginalIcon/>
  } else if (format === "sh") {
    return <BashOriginalIcon/>
  } else if (format === "ts") {
    return <TypescriptOriginalIcon/>
  }else if (format === "osx") {
    return <AppleOriginalIcon/>
  }
  return <></>
}