import Carousel from 'react-bootstrap/Carousel';

export function ProjectCarousel() {
  return (
    <>
        <h1>Projects</h1>
        <a className="GithubTile" href="https://github.com/omars-lab/3d-models">
            <img src="https://github-readme-stats.vercel.app/api/pin/?username=omars-lab&repo=3d-models" alt="Readme Card"></img>
        </a>
        <a className="GithubTile" href="https://github.com/omars-lab/3d-models">
            <img src="https://github-readme-stats.vercel.app/api/pin/?username=omars-lab&repo=3d-models" alt="Readme Card"></img>
        </a>
        <a className="GithubTile" href="https://github.com/omars-lab/3d-models">
            <img src="https://github-readme-stats.vercel.app/api/pin/?username=omars-lab&repo=3d-models" alt="Readme Card"></img>
        </a>
        <br/>
    </>

  );
}

export default ProjectCarousel;