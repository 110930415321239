export const isTabletOrMobileQuery = { query: '(max-width: 1224px)' };

export function deriveId(label) {
    return label.toLowerCase().replaceAll(" ", "-")
}

export const nameToAnchor = (name) => deriveId(name);

export function deriveNavigationFromSkillsets(skillsets) {
    return sortArrayByStringField(skillsets, (x) => x.category).map(
        (skillset) => {
            // return skillset.skills.map(
            //     skill => ({
            //     label: skill.skill,
            //     link: `#${deriveId(skill.skill)}`,
            //     format: "link"
            //     })
            // );
            return {
                label: skillset.category,
                link: `#${deriveId(skillset.category)}`,
                format: "link"
            };
        }
    ).flat();
}

export function sortArrayByStringField(a, selector) {
    return a.slice().sort((one, two) => (selector(one) > selector(two) ? 1 : -1))
}