import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { deriveId, sortArrayByStringField, isTabletOrMobileQuery } from './Utils';
import { useMediaQuery } from 'react-responsive'
import CentosOriginalIcon from "react-devicons/centos/plain";

function CommingSoon(text) {
  return <>
    {text} <Badge bg="secondary">Comming Soon</Badge>
  </>
}

function PortfolioNavBar(skillsets) {
  const isTabletOrMobile = useMediaQuery(isTabletOrMobileQuery);
  return (
    <Navbar expand="lg" className="bg-body-tertiary" bg="dark" data-bs-theme="dark">
      <Container fluid>
        <Navbar.Brand href="#"><CentosOriginalIcon/>Omar's Portfolio</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link href="#action1">Home</Nav.Link>
            <Nav.Link href="#intro">Intro</Nav.Link>
            <Nav.Link href="#mindset">Mindset</Nav.Link>
            {
              !isTabletOrMobile && 
                <NavDropdown title="Skills" id="navbarScrollingDropdown">
                {sortArrayByStringField(skillsets, (x) => x.category).slice().sort((one, two) => (one.category > two.category ? 1 : -1)).map(
                  (skillset) => {
                    const anchor = `#${deriveId(skillset.category)}`;
                    return <>
                      <NavDropdown.Item href={anchor}>{skillset.category}</NavDropdown.Item>
                    </>;
                  }
                )}
                </NavDropdown>
            }
            <Nav.Link href="/resume.pdf">
              Resume
            </Nav.Link>
            <NavDropdown title={CommingSoon("Extras")} id="navbarScrollingDropdown">
                <NavDropdown.Item href="#">{CommingSoon("Support Me")}</NavDropdown.Item>
                <NavDropdown.Item href="#">{CommingSoon("Social Media (linkedin)")}</NavDropdown.Item>
                <NavDropdown.Item href="#">{CommingSoon("Dashboard")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={CommingSoon("Projects")} id="navbarScrollingDropdown">
                <NavDropdown.Item href="#">{CommingSoon("3d Prints")}</NavDropdown.Item>
                <NavDropdown.Item href="#">{CommingSoon("Sacred Patterns")}</NavDropdown.Item>
                <NavDropdown.Item href="#">{CommingSoon("Blogs")}</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Skill (Comming Soon)"
              className="me-2"
              aria-label="Search"
            />
            <Button variant="outline-success">Search</Button>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default PortfolioNavBar;